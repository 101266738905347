import * as React from 'react';
import { GlobalContext } from './GlobalContext';
import GeneralSettingsType from './IGeneralSettings';

const getCurrentVersion = (versionAsObject: Record<string, unknown>): string =>
    Object.keys(versionAsObject)
        .map((propertyKey) => versionAsObject[propertyKey])
        .toString()
        .replace(/,/g, '');

export const GlobalContextProvider: React.FunctionComponent<any> = ({ children, settings }: any) => {
    const generalSettings: GeneralSettingsType = {
        ...settings.WebSettings,
        displayName: settings.WebSettings.displayName.split(','),
    };

    const [getProviderValue, setProviderValue] = React.useState({
        accessToken: sessionStorage.passcore_accessToken,
        canResetPassword: settings.CanReset,
        complexPassword: settings.IsComplexPassword,
        currentVersion: getCurrentVersion(settings.CurrentVersion),
        forceReset: sessionStorage.passcore_forceReset === 'true',
        generalSettings: { ...generalSettings },
        identityType: settings.IdentityType,
        languages: settings.Languages,
        licenseData: settings.LicenseData,
        minPasswordLength: settings.MinPasswordLength,
        showSetup: settings.showSetup,
        tokenExpirationTime: sessionStorage.passcore_expTime,
        updateContext: (newState: any) => setProviderValue({ ...newState }),
        userAttributes: { ...settings.UserAttributes },
        userDisplayName: '',
        userIsAuthenticated:
            sessionStorage.passcore_authenticate !== undefined
                ? JSON.parse(sessionStorage.passcore_authenticate)
                : false,
        username: '',
    });

    return <GlobalContext.Provider value={getProviderValue}>{children}</GlobalContext.Provider>;
};
