const defaultStyles = (theme: any) => ({
    appBarSection: {
        alignItems: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        margin: '4px',
        maxHeight: '48px',
        minWidth: '200px',
    },
    appBarSingleModeSection: {
        alignItems: 'center',
        display: 'inline-flex',
        height: '48px',
        justifyContent: 'center',
        margin: '4px',
        maxHeight: '48px',
        minWidth: '200px',
    },
    booleanEditor: {
        padding: '5% 0% 0% 32%',
    },
    button: {
        fontSize: '0.7rem',
    },
    cropperCurrentPhoto: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: '72px',
        height: '250px',
        width: '250px',
    },
    cropperInput: {
        cursor: 'pointer',
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        opacity: 0,
        overflow: 'hidden',
        width: '100%',
        zIndex: 4,
    },
    cropperInputContainer: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: `${theme.palette.grey[400]} dashed`,
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        marginRight: '32px',
    },
    cropperInputContainerActive: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: `${theme.palette.primary.main} dashed`,
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        marginRight: '32px',
    },
    cropperInputMessage: {
        color: theme.palette.grey[400],
        position: 'absolute' as const,
    },
    cropperInputMessageActive: {
        color: theme.palette.primary.main,
        position: 'absolute' as const,
        textShadow: `2px 2px 8px ${theme.palette.primary.main}`,
    },
    cropperPreview: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: '72px',
        height: '250px',
        position: 'sticky' as const,
        width: '250px',
    },
    detailSectionContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    detailsContainer: {
        display: 'flex',
    },
    detailsLoadingIcon: {
        height: '160px !important',
        width: '160px !important',
    },
    detailsPaper: {
        display: 'flex',
        flexGrow: 1,
        minHeight: '28%',
    },
    detailsPhoto: {
        backgroundColor: theme.palette.primary.main,
        fontSize: '54px',
        height: '225px',
        width: '225px',
    },
    detailsSubtitle: {
        color: theme.palette.grey[500],
        fontSize: '14px',
    },
    detailsTitle: {
        fontSize: '27px',
        fontWeight: 600,
        marginRight: '12px',
    },
    detailsValue: {
        fontSize: '14px',
        letterSpacing: '0px',
        lineHeight: '22px',
        marginLeft: '5px',
    },
    dialogCloseButton: {
        cursor: 'pointer',
        position: 'absolute' as const,
        right: '8px',
        top: '14px',
    },
    dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    directoryRow: {
        cursor: 'pointer',
        fontSize: '12px',
    },
    editableProfilePhoto: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: '72px',
        height: '250px',
        marginBottom: '4%',
        width: '250px',
    },
    editorDialog: {
        width: 'auto',
    },
    errorBoundBody: {
        color: 'red',
        fontFamily: 'verdana',
        fontSize: 10,
    },
    errorBoundDetails: {
        fontFamily: 'verdana',
        fontSize: 9,
        whiteSpace: 'pre-wrap' as const,
    },
    errorBoundPaper: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center',
        padding: '50px',
        width: '80%',
    },
    errorBoundTitle: {
        fontFamily: 'verdana',
        fontSize: 9,
        whiteSpace: 'pre-wrap' as const,
    },
    footer: {
        backgroundColor: theme.palette.primary.main,
        bottom: '0',
        left: '0',
        padding: '8px',
        position: 'fixed' as const,
        width: '100%',
    },
    footerSubtitle: {
        color: '#FFFFFF',
        fontSize: '14px',
        marginBottom: '4px',
        marginTop: '4px',
    },
    formHelpText: {
        lineHeight: '22px',
        marginLeft: '16px',
    },
    helpdeskWarn: {
        backgroundColor: '#fc7171',
        border: '2px solid red',
        height: '100px',
        paddingBottom: '10px',
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '10px',
        width: '280px',
    },
    homePaper: {
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '10px',
        padding: '20px',
    },
    loadingIcon: {
        height: '100px !important',
        margin: '0px !important',
        width: '100px !important',
    },
    loginTitle: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center' as const,
    },
    loginTitleForSingleForm: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center' as const,
        paddingTop: '50px',
    },
    loginTitleForSingleFormMobile: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        justifyContent: 'center' as const,
    },
    mainTitle: {
        color: '#fff',
        fontSize: '18px',
        textTransform: 'none' as const,
    },
    mobileGrid: {
        display: 'flex',
        flexDirection: 'column' as const,
        flexWrap: 'wrap' as const,
        padding: '1.5%',
    },
    mobileGridDivider: {
        backgroundColor: theme.palette.primary.main,
        height: '3px',
    },
    mobileGridPropertyHeader: {
        padding: '2% 0% 2% 0%',
    },
    mobileGridPropertyName: {
        color: '#b7b7b7',
    },
    mobileGridPropertyValue: {
        marginLeft: '2%',
    },
    orgChartContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column' as const,
        flexGrow: 1,
        height: '100%',
        justifyContent: 'center',
        marginBottom: '40px',
        marginTop: '40px',
    },
    orgChartDetailsPhoto: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: '96px',
        height: '250px',
        width: '250px',
    },
    orgChartDialogContentContainer: {
        display: 'flex',
        flexDirection: 'row' as const,
        position: 'relative' as const,
    },
    orgChartDivider: {
        backgroundColor: 'black',
        height: '100%',
        width: '2px',
    },
    orgChartItem: {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer',
    },
    orgChartItemContainer: {
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'row' as const,
        height: '100px',
        justifyContent: 'center',
        width: '85%',
    },
    orgChartItemName: {
        fontSize: '18px',
    },
    orgChartItemTitle: {
        color: theme.palette.grey[500],
        fontSize: '12px',
    },
    orgChartPreviewDivider: {
        backgroundColor: '#000',
        height: '2px',
        width: '87%',
    },
    orgChartReporter: {
        backgroundColor: theme.palette.secondary.main,
    },
    orgChartSubdivider: {
        backgroundColor: '#000',
        height: '100%',
        width: '2px',
    },
    orgChartSubdividersContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row' as const,
        flexGrow: 1,
        height: '50px',
        justifyContent: 'space-between',
        width: '74%',
    },
    passwordDescript: {
        fontSize: '12px',
        whiteSpace: 'pre-wrap' as const,
    },
    profilePhotoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: '0%',
    },
    progressBar: {
        color: '#000000',
        display: 'flex',
        flexGrow: 1,
        marginBottom: '20px',
    },
    progressBarColorHigh: {
        backgroundColor: '#4caf50',
    },
    progressBarColorLow: {
        backgroundColor: '#ff5722',
    },
    progressBarColorMedium: {
        backgroundColor: '#ffc107',
    },
    progressBarReset: {
        width: '100%',
    },
    recaptcha: {
        paddingLeft: '11.5%',
        paddingTop: '4%',
    },
    recaptchaForSingleForm: {
        marginLeft: 'calc(50% - 180px)',
        paddingTop: '4%',
    },
    resetCard: {
        borderColor: '#d8d8d8',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: 'none',
        height: '100%',
        minWidth: '50%',
        position: 'relative' as const,
        width: '100%',
    },
    resetTitle: {
        color: '#fff',
        display: 'flex',
        fontSize: '18px',
    },
    searchBoxContainer: {
        backgroundColor: '#fff',
        borderRadius: '0',
        display: 'inline-flex',
        maxHeight: '70%',
        maxWidth: '80%',
        minWidth: '75%',
    },
    searchBoxDivider: {
        color: theme.palette.primary.main,
        height: '27px',
        marginBottom: '2px',
        marginTop: '4px',
        width: '1px',
    },
    searchBoxIcon: {
        margin: '8px',
    },
    searchBoxInput: {
        fontSize: '16px',
        marginLeft: '8px',
        marginRight: '8px',
        width: '100%',
    },
    stepper: {
        width: '100%',
    },
    textCloseButton: {
        color: theme.palette.common.white,
        margin: '10px',
    },
    textField: {
        marginBottom: '20px',
    },
    textFieldLabel: {
        fontSize: '16px',
        fontWeight: 500,
    },
    uploadProfilePhotoDialog: {
        display: 'flex',
        flexGrow: 1,
        maxHeight: '95%',
        maxWidth: '75%',
    },
    userLink: {
        fontSize: '14px',
        lineHeight: '22px',
        marginLeft: '5px',
    },
    userMenuThumbnail: {
        backgroundColor: theme.palette.secondary.main,
        height: '40px',
        width: '40px',
    },
});

export default defaultStyles;
