import * as React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { render } from 'react-dom';
import GeneralErrorBoundary from '../Components/ErrorBoundary/GeneralErrorBoundary';
import { Main } from './Main';
import { UnoTheme } from 'uno-material-ui';

render(
    <ThemeProvider theme={UnoTheme}>
        <GeneralErrorBoundary>
            <Main />
        </GeneralErrorBoundary>
    </ThemeProvider>,
    document.getElementById('rootNode'),
);
