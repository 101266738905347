import * as React from 'react';
import { UnoSuspense } from 'uno-material-ui';
import { GlobalContext } from '../../Provider/GlobalContext';

const LazyWizardWarning = React.lazy(() => import('../Warning/WizardWarning'));
const LazySingleForm = React.lazy(() => import('../SingleForm/Components/SingleForm'));
const LazyEntryPoint = React.lazy(() => import('./EntryPoint'));
const LazyResetPasswordStepper = React.lazy(() => import('../ResetPassword/ResetPasswordStepper'));

const getMode = (mode: number) => {
    switch (mode) {
        case 1:
            return <LazySingleForm />;
        case 2:
            return <LazyResetPasswordStepper />;
        default:
            return <LazyEntryPoint />;
    }
};

export const ModeSelector: React.FunctionComponent<any> = () => {
    const { showSetup, generalSettings } = React.useContext(GlobalContext);

    React.useEffect(() => {
        document.title = generalSettings.applicationTitle;
    }, []);

    if (showSetup) {
        return (
            <UnoSuspense>
                <LazyWizardWarning />
            </UnoSuspense>
        );
    }

    return <UnoSuspense>{getMode(generalSettings.useSingleFormMode)}</UnoSuspense>;
};
