import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { RequestURLs } from '../utils/Params';

const transResult = {
    debug: document.location.host === 'localhost',
    defaultNS: 'translations',
    detection: {
        order: ['navigator', 'htmlTag', 'querystring'],
    },
    fallbackLng: '',
    interpolation: {
        escapeValue: false,
    },
    keySeparator: ';',
    lng: '',
    ns: ['translations'],
    resources: {},
};

async function resolveAppSettings(): Promise<any> {
    const response = await fetch(RequestURLs.appSettings);

    if (!response || response.status !== 200) {
        throw new Error('Error fetching settings.');
    }

    const responseBody = await response.text();
    const data = responseBody ? JSON.parse(responseBody) : {};

    if (!data.I18n) {
        throw new Error('Error fetching settings.');
    }

    const keys = Object.keys(data.I18n);

    const i18Config = transResult;
    if (sessionStorage.hasOwnProperty('passcoreI18Config')) {
        i18Config.lng = sessionStorage.getItem('passcoreI18Config');
    } else {
        if (data.WebApp.DefaultLanguage) {
            i18Config.lng = data.WebApp.DefaultLanguage;
            i18Config.fallbackLng = data.WebApp.DefaultLanguage;
        }
    }

    i18Config.resources = keys.reduce(
        (prev: any, current: any) => ({
            [current]: {
                translations: data.I18n[current],
            },
            ...prev,
        }),
        {},
    );

    const langs = keys.reduce(
        (prev: any, current: any) => ({
            [current]: data.I18n[current].Lang_Name,
            ...prev,
        }),
        {},
    );

    i18n.use(LanguageDetector).use(initReactI18next).init(i18Config);

    return {
        generalSettings: {
            CanReset: data.CanReset,
            CurrentVersion: { ...data.Version },
            IdentityType: data.IdentityType,
            IsComplexPassword: data.IsComplexPassword,
            Languages: langs,
            LicenseData: { ...data.LicenseData },
            MinPasswordLength: data.MinPasswordLength,
            UserAttributes: { ...data.UserAttributes },
            WebSettings: {
                applicationTitle: data.WebApp.ApplicationTitle,
                canTranslate: data.WebApp.CanTranslate,
                defaultLanguage: data.WebApp.DefaultLanguage,
                displayName: data.WebApp.DisplayName,
                errorsBeforeReCaptcha: data.WebApp.ErrorsBeforeReCaptcha,
                errorsBeforeLoginBlock: data.WebApp.ErrorsBeforeLoginBlock,
                footerText: data.WebApp.FooterText,
                helpdeskAddress: data.WebApp.HelpdeskAddress,
                logoHeight: data.WebApp.LogoHeight,
                logoWidth: data.WebApp.LogoWidth,
                recaptchaPublicKey: data.WebApp.RecaptchaPublicKey,
                showDirectory: data.WebApp.ShowDirectory,
                showEditInfo: data.WebApp.ShowEditInfo,
                showOrganizationChart: data.WebApp.ShowOrganizationChart,
                showProfilePicture: data.WebApp.ShowProfilePicture,
                showRecaptcha: data.WebApp.ShowRecaptcha,
                themePrimaryColor: data.WebApp.ThemePrimaryColor,
                themeSecondaryColor: data.WebApp.ThemeSecondaryColor,
                useReCaptcha: data.WebApp.UseReCaptcha,
                useSingleFormMode: data.WebApp.UseSingleFormMode,
            },
            showSetup: data.ShowSetup,
        },
        i18n,
    };
}

export default resolveAppSettings;
