import Paper from '@material-ui/core/Paper/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import defaultStyles from '../../DefaultStyles';
import { Title } from '../Title/Title';

class ErrorBoundary extends React.Component<any, any> {
    public state: any = {
        error: null as any,
        errorData: null as any,
    };

    constructor(props: any) {
        super(props);
        const { history } = this.props;

        history.listen(() => {
            if (this.state.errorData) {
                this.setState({
                    errorData: null,
                });
            }
        });
    }

    public componentDidCatch(error: any, errorData: any) {
        this.setState({
            error,
            errorData,
        });
    }

    public render() {
        if (!this.state.errorData) {
            return this.props.children;
        }

        const { classes, t } = this.props;
        const errorDesc = this.state.error && this.state.error.toString() ? this.state.error.toString() : null;

        return (
            <React.Fragment>
                <Title value={t('ErrorBoundaryTitle')} titleClass={classes.appTitle} />
                <Paper className={classes.errorBoundPaper} elevation={8}>
                    <Typography className={classes.errorBoundTitle} variant="subtitle1">
                        {errorDesc}
                    </Typography>
                    <br />
                    <details className={classes.errorBoundDetails}>
                        <Typography className={classes.errorBoundBody} variant="body1">
                            {this.state.errorData.componentStack}
                        </Typography>
                    </details>
                </Paper>
            </React.Fragment>
        );
    }
}

export default compose(withRouter, withStyles(defaultStyles), withTranslation('translations'))(ErrorBoundary);
