import * as React from 'react';
import GeneralSettingsType from './IGeneralSettings';

interface GlobalContextType {
    accessToken: string;
    canResetPassword: boolean;
    currentVersion: string;
    forceReset: boolean;
    generalSettings: GeneralSettingsType;
    identityType: number;
    languages: any;
    licenseData: any;
    minPasswordLength: number;
    complexPassword: boolean;
    showSetup: boolean;
    tokenExpirationTime: number;
    updateContext: (newState: GlobalContextType) => void;
    userDisplayName: string;
    userAttributes: any;
    userIsAuthenticated: boolean;
    username: string;
}

export const GlobalContext = React.createContext<GlobalContextType>({
    accessToken: null,
    canResetPassword: false,
    complexPassword: false,
    currentVersion: null,
    forceReset: false,
    generalSettings: null,
    identityType: 0,
    languages: null,
    licenseData: null,
    minPasswordLength: 0,
    showSetup: false,
    tokenExpirationTime: null,
    updateContext: null,
    userAttributes: null,
    userDisplayName: null,
    userIsAuthenticated: false,
    username: null,
});

interface GlobalActionsContextType {
    authenticated: any;
    changeLanguage: any;
    changePassword: any;
    changePasswordSingleForm: any;
    codeNotifier: any;
    fetchRequest: any;
    getTeam: any;
    getTubularClient: any;
    login: any;
    logout: any;
    resetPassword: any;
    setDisplayName: any;
    setForceReset: any;
    uploadFileToUserAttribute: any;
    validateUser: any;
}

export const GlobalActionsContext = React.createContext<GlobalActionsContextType>({
    authenticated: null,
    changeLanguage: null,
    changePassword: null,
    changePasswordSingleForm: null,
    codeNotifier: null,
    fetchRequest: null,
    getTeam: null,
    getTubularClient: null,
    login: null,
    logout: null,
    resetPassword: null,
    setDisplayName: null,
    setForceReset: null,
    uploadFileToUserAttribute: null,
    validateUser: null,
});
