import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { GridRequest, ITubularHttpClient, ShallowHttpClient } from 'tubular-common';
import { RequestMethod, RequestURLs } from '../utils/Params';
import { fetchController } from '../utils/RequestController';
import { GlobalActionsContext, GlobalContext } from './GlobalContext';
import { sendMessage } from '../utils/showMessage';
import { useTranslation } from 'react-i18next';

const ticksToMilliseconds = (ticks: number) => {
    const ticksToEpochStartDate = 621355968000000000;
    return (ticks - ticksToEpochStartDate) / 10000;
};

export const GlobalActionsProvider = ({ children }: any) => {
    const history = useHistory();
    const [t] = useTranslation();
    const [forceReset, setForceReset] = React.useState(false);
    const globalContext = React.useContext(GlobalContext);

    const validateToken = () => {
        const expirationDate = new Date(+globalContext.tokenExpirationTime);
        const timeNow = new Date(Date.now());

        return timeNow < expirationDate;
    };

    const resetState = () => {
        globalContext.updateContext({
            ...globalContext,
            accessToken: '',
            forceReset: false,
            tokenExpirationTime: 0,
            userDisplayName: '',
            userIsAuthenticated: false,
            username: '',
        });
    };

    const fetchRequest = async (url: string, method = RequestMethod.Get, body?: any) => {
        try {
            const data = await fetchController(url, globalContext.accessToken, method, body);

            if (!data || data.error) {
                if (data && data.error === 'Invalid user') {
                    resetState();
                    history.push('/');
                } else {
                    sendMessage(t(data ? data.error : 'Snackbar_ErrorFetch'), 'error');
                }

                return null;
            }

            return data;
        } catch (e) {
            resetState();
        }
    };

    const setInitialState = (state: any, userIsAuthenticated = true) => {
        globalContext.updateContext({
            ...globalContext,
            accessToken: state.access_token,
            forceReset: state.mustChangePassword,
            tokenExpirationTime: ticksToMilliseconds(state.expires_in),
            userDisplayName: '',
            userIsAuthenticated,
            username: state.userName,
        });

        sessionStorage.setItem('passcore_accessToken', state.access_token);
        sessionStorage.setItem('passcore_expTime', String(ticksToMilliseconds(state.expires_in)));
        sessionStorage.setItem('passcore_authenticate', 'true');
        sessionStorage.setItem('passcore_forceReset', state.mustChangePassword);
    };

    const provActions = {
        authenticated: () => {
            if (!globalContext.userIsAuthenticated) {
                return false;
            }

            if (validateToken()) {
                return true;
            }

            if (globalContext.accessToken !== '') {
                resetState();
            }

            return false;
        },
        changeLanguage: (language: string) => {
            if (sessionStorage.hasOwnProperty('passcoreI18Config')) {
                sessionStorage.removeItem('passcoreI18Config');
            }
            sessionStorage.setItem('passcoreI18Config', language);
        },
        changePassword: async (
            newPassword: string,
            passwordVerify: string,
            isResetFromWizard: boolean,
        ): Promise<boolean> => {
            if (newPassword !== '' || passwordVerify !== '') {
                const response = await fetchRequest(
                    RequestURLs.changePassword,
                    RequestMethod.Post,
                    JSON.stringify({
                        NewPassword: newPassword,
                        NewPasswordVerify: passwordVerify,
                    }),
                );

                if (response === null || (response.ErrorCode !== null && response.ErrorCode !== undefined)) {
                    let message;
                    switch (response.ErrorCode) {
                        case 8:
                            message = 'Snackbar_InvalidPasswordLength';
                            break;
                        case 9:
                            message = 'Snackbar_DisplayNameOnPassword';
                            break;
                        case 10:
                            message = 'Snackbar_SamAccountInPassword';
                            break;
                        case 11:
                            message = 'Snackbar_InvalidPasswordPolicy';
                            break;
                        case 12:
                            message = 'Snackbar_InvalidPasswordEntropy';
                            break;
                        default:
                            message = response.Message ? response.Message : 'Snackbar_InvalidPasswordStrength';
                            break;
                    }
                    sendMessage(t(message), 'error');
                    return false;
                }

                sendMessage(t('Snackbar_SuccessChangePassword'));
                if (isResetFromWizard) {
                    sessionStorage.clear();
                    resetState();
                    history.push('/');
                    return true;
                }
            }
            return false;
        },
        changePasswordSingleForm: async (formData: Record<string, unknown>) => {
            const response = await fetchRequest(
                RequestURLs.changePasswordSingleForm,
                RequestMethod.Post,
                JSON.stringify(formData),
            );

            if (response === null || (response.ErrorCode !== null && response.ErrorCode !== undefined)) {
                let message;
                switch (response.ErrorCode) {
                    case 8:
                        message = 'Snackbar_InvalidPasswordLength';
                        break;
                    case 9:
                        message = 'Snackbar_DisplayNameOnPassword';
                        break;
                    case 10:
                        message = 'Snackbar_SamAccountInPassword';
                        break;
                    case 11:
                        message = 'Snackbar_InvalidPasswordPolicy';
                        break;
                    case 12:
                        message = 'Snackbar_InvalidPasswordEntropy';
                        break;
                    default:
                        message = response.Message ? response.Message : 'Snackbar_InvalidPasswordStrength';
                        break;
                }
                sendMessage(t(message), 'error');
                return false;
            }
            if (response.Error) {
                sendMessage(t(response.message), 'error');
                return false;
            }
            sendMessage(t('Snackbar_SuccessChangePassword'));
            resetState();
            return true;
        },
        codeNotifier: (input: number) =>
            fetchRequest(
                RequestURLs.codeNotifier,
                RequestMethod.Post,
                JSON.stringify({ MethodInput: input, UserName: globalContext.username }),
            ),
        fetchRequest,
        getTeam: async () => {
            const response = await fetchRequest(RequestURLs.getTeam, RequestMethod.Get, null);

            if (!response || response.error) {
                return;
            }

            return response;
        },
        getTubularClient: (baseUrl: string): ITubularHttpClient =>
            new ShallowHttpClient(baseUrl, (url: string, request: GridRequest) =>
                fetchRequest(url, RequestMethod.Post, JSON.stringify(request)),
            ),
        login: async (username: string, password: string, usingRecaptcha = false, token?: string) => {
            let bodyToPost: string =
                `username=${encodeURIComponent(username)}` + `&password=${encodeURIComponent(password)}`;

            if (usingRecaptcha) {
                bodyToPost = bodyToPost.concat(`&recaptcha=${encodeURIComponent(token)}`);
            }

            const response = await fetchRequest(RequestURLs.login, RequestMethod.Post, bodyToPost);

            if (!response) {
                return null;
            }

            if (response.invalidRecaptcha) {
                sendMessage(t('Snackbar_InvalidRecaptcha'), 'error');
                return null;
            }

            if (response.wrongUserOrPassword || response.invalidMailAddress) {
                sendMessage(t('Snackbar_FailLogin'), 'error');
                if (response.recaptchaNeedIt || response.invalidRecaptcha) {
                    return { recaptchaNeedIt: response.recaptchaNeedIt };
                } else if (response.blockLogin) {
                    sendMessage(t('Snackbar_LoginBlocked'), 'error');
                    return { blockLogin: response.blockLogin };
                }
                return null;
            }
            setForceReset(response.mustChangePassword);
            setInitialState(response);
            history.push('/');

            return null;
        },
        logout: () => {
            sessionStorage.clear();
            resetState();
            history.push('/');
        },
        resetPassword: async (notifMethod: string, pinCode: string): Promise<boolean> => {
            const response = await fetchRequest(
                RequestURLs.resetPassword,
                RequestMethod.Post,
                JSON.stringify({
                    MethodInput: notifMethod,
                    PinCode: pinCode,
                    UserName: globalContext.username,
                }),
            );

            if (!response) {
                return false;
            }

            setInitialState(response, false);
            return true;
        },
        setDisplayName: (name: string) => {
            globalContext.updateContext({
                ...globalContext,
                userDisplayName: name,
            });
        },
        setForceReset,
        uploadFileToUserAttribute: async (attribute: any, newValue: File) => {
            const formData = new FormData();
            formData.set('Files', newValue, 'mypicture.jpg');

            const response = await fetchRequest(
                RequestURLs.userDataFile + '/' + attribute,
                RequestMethod.Post,
                formData,
            );

            if (!response) {
                return;
            }

            sendMessage(t('Snackbar_SuccessChangeData'));
            history.push('/');
        },
        validateUser: async (userName: string) => {
            const response = await fetchRequest(
                RequestURLs.validateUser,
                RequestMethod.Post,
                JSON.stringify({ UserName: userName }),
            );

            if (!response) {
                return;
            }

            if (response.Validation === 0) {
                sendMessage(t('Snackbar_UserNotFound'), 'error');

                return;
            }

            globalContext.updateContext({
                ...globalContext,
                username: userName,
                forceReset: forceReset,
            });
            return response;
        },
    };

    return <GlobalActionsContext.Provider value={provActions}>{children}</GlobalActionsContext.Provider>;
};
