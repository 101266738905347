import Paper from '@material-ui/core/Paper/Paper';
import Typography from '@material-ui/core/Typography/Typography';
import * as React from 'react';

class GeneralErrorBoundary extends React.Component<any, any> {
    public state = {
        error: null as any,
        errorData: null as any,
    };

    constructor(props: any) {
        super(props);
    }

    public componentDidCatch(error: any, errorData: any) {
        this.setState({
            error,
            errorData,
        });
    }

    public render() {
        if (!this.state.errorData) {
            return this.props.children;
        }

        const errorDesc = this.state.error && this.state.error.toString() ? this.state.error.toString() : null;

        return (
            <React.Fragment>
                <Typography variant="h3">Something went wrong...</Typography>
                <Paper elevation={3}>
                    <Typography variant="subtitle1">{errorDesc}</Typography>
                    <br />
                    <details>
                        <Typography variant="body1">{this.state.errorData.componentStack}</Typography>
                    </details>
                </Paper>
            </React.Fragment>
        );
    }
}

export default GeneralErrorBoundary;
