import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { LoadingIcon } from 'uno-material-ui';
import { useEffectWithLoading } from 'uno-react';
import { ModeSelector } from '../Components/EntryPoint/ModeSelector';
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary';
import { loadReCaptcha } from '../Components/GoogleReCaptcha';
import { AppThemeProvider } from '../Provider/AppThemeProvider';
import { GlobalActionsProvider } from '../Provider/GlobalActionsProvider';
import { GlobalContextProvider } from '../Provider/GlobalContextProvider';
import { SnackbarContainer } from 'uno-material-ui';
import resolveAppSettings from './AppSettings';

const defaultState = {
    generalSettings: {
        WebSettings: {
            recaptchaPublicKey: null,
        } as any,
    },
};

const loadingTitleStyle = {
    marginTop: '15% !important',
};

const loadingIconStyle = {
    height: '100px !important',
    margin: '0px !important',
    width: '100px !important',
};

export const Main: React.FunctionComponent = () => {
    const [settings, isLoading] = useEffectWithLoading(resolveAppSettings, defaultState, []);

    React.useEffect(() => {
        if (
            settings.generalSettings.WebSettings.recaptchaPublicKey &&
            settings.generalSettings.WebSettings.recaptchaPublicKey !== '' &&
            settings.generalSettings.WebSettings.useReCaptcha
        ) {
            loadReCaptcha();
        }
    }, [settings.generalSettings.WebSettings]);

    if (isLoading) {
        return (
            <Grid container={true} alignItems="center" direction="column" justify="center">
                <Grid item={true} key="title">
                    <Typography variant="h3" align="center" style={loadingTitleStyle}>
                        passcorepro
                    </Typography>
                </Grid>
                <Grid item={true}>
                    <LoadingIcon style={loadingIconStyle} />
                </Grid>
            </Grid>
        );
    }

    return (
        <AppThemeProvider settings={settings.generalSettings}>
            <I18nextProvider i18n={settings.i18n}>
                <BrowserRouter>
                    <ErrorBoundary>
                        <GlobalContextProvider settings={settings.generalSettings}>
                            <SnackbarContainer />
                            <GlobalActionsProvider>
                                <ModeSelector />
                            </GlobalActionsProvider>
                        </GlobalContextProvider>
                    </ErrorBoundary>
                </BrowserRouter>
            </I18nextProvider>
        </AppThemeProvider>
    );
};
