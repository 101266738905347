import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import * as React from 'react';

export const AppThemeProvider: React.FunctionComponent<any> = ({ children, settings }: any) => {
    const theme = createMuiTheme({
        palette: {
            primary: { main: settings.WebSettings.themePrimaryColor },
            secondary: { main: settings.WebSettings.themeSecondaryColor },
        },
    });

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
